import React, { createContext, useState, useEffect, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { APICore } from './helpers/api/apiCore';

const apiLocal = new APICore();

type SocketContextType = Socket | null;

// Create a context for the socket
const SocketContext = createContext<SocketContextType>(null);

// SocketProvider Component
const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [socket, setSocket] = useState<SocketContextType>(null);
    const loggedInUser = apiLocal.getLoggedInUser();
    const token = loggedInUser?.token;
    useEffect(() => {
            const socketInstance: Socket = io(process.env.REACT_APP_SOCKET_URL, {
                transports: ['websocket'],
                auth: { token },
            });

            setSocket(socketInstance);

            // Debugging
            socketInstance.on('connect', () => {
                console.log('Socket connected:', socketInstance.id);
            });

            socketInstance.on('disconnect', () => {
                console.log('Socket disconnected');
            });

            // Clean up on unmount
            return () => {
                socketInstance.close();
            };
    }, [token]);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

// Custom hook for using the socket instance in components
const useSocket = (): SocketContextType => {
    const context = useContext(SocketContext);
    if (context === null) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};

export { SocketProvider, useSocket };
