import axios from "axios";

class APIServices {
  /**
   * Signup (GET request)
   */
  async signup(endpoint: string, body: any): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const url = `${baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  }

  /**
   * Signup (POST request)
   */
  async signupPost(endpoint: string, body: any): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const url = `${baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  }

  /**
   * PATCH request
   */
  async patchRequest(endpoint: string, body: any): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;
    const url = `${baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  }

  /**
   * POST request
   */
  async postRequest(endpoint: string, body: any): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;
    const url = `${baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  }

  /**
   * POST request with Bearer token
   */
  async postRequestwithBearer(endpoint: string, body: any, token: string): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const url = `${baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  }

  /**
   * PUT request
   */
  async putRequest(endpoint: string, body: any): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const url = `${baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  }

  /**
   * DELETE request
   */
  async deleteRequest(endpoint: string): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;
    const url = `${baseURL}/${endpoint}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    });

    // Handle 204 No Content response properly
    if (response.status === 204) {
      return { status: 204 }; // Return a status object since there's no JSON response
    }

    // For other responses, return the JSON data
    return response.json();
  }


  /**
   * Multipart request
   */
  async multipartRequest(endpoint: string, body: FormData): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;
    const url = `${baseURL}/${endpoint}`;
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Axios already parses JSON response
  }

  /**
   * POST request with token in query params
   */
  async postRequestWithToken(endpoint: string, body: any, token: string): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const url = `${baseURL}/${endpoint}?token=${token}`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    return res;
  }

  /**
   * GET request with Bearer token and optional params
   */
  async getRequest(endpoint: string, params: Record<string, string> = {}): Promise<any> {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;
    const queryString = new URLSearchParams(params).toString();
    const url = `${baseURL}/${endpoint}${queryString ? `?${queryString}` : ""}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  }
}

export default APIServices;
