import AWS from "aws-sdk";

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
    signatureVersion: "v4",
});


/**
 * Generate a Signed URL for accessing private S3 objects.
 */
const getSignedUrl = async (filePath: string, expirySeconds = 3600): Promise<string> => {
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;

    if (!bucketName) {
        console.error("AWS Bucket Name is missing in environment variables.");
        return "";
    }

    // Extract only the file path from a full URL (if provided)
    let cleanedFilePath = filePath;
    if (filePath.startsWith("http")) {
        try {
            const url = new URL(filePath);
            cleanedFilePath = decodeURIComponent(url.pathname.substring(1)); // Remove leading "/"
        } catch (error) {
            console.error("Invalid file URL:", error);
            return "";
        }
    }

    const params = {
        Bucket: bucketName,
        Key: cleanedFilePath, // Use cleaned path
        Expires: expirySeconds,
    };

    console.log("S3 Params:", params); // Log S3 params for debugging

    try {
        const signedUrl = await s3.getSignedUrlPromise("getObject", params);
        console.log("Generated Signed URL:", signedUrl); // Log the signed URL
        return signedUrl;
    } catch (error) {
        console.error("Error generating signed URL:", error);
        return "";
    }
};


export { getSignedUrl };
