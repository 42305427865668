import APIServices from "../dynamic/APIServices";
import { toast } from "react-toastify";

// Initialize API service
const apiService = new APIServices();
  
/**
 * Reusable function to delete a row.
 * @param rowId - ID of the row to be deleted.
 * @param modelName - Type of the Table name (plant, fault, leave, notification etc).
 * @param endPoint - Second End Point Base name
 * @param loadData - Function to refresh the table data after deletion.
 * @param loggedInUserToken - Admin Users Authentication token.
 */
const handleDelete = async (
    rowId: number,
    modelName: string,
    endPoint: string,
    loadData: () => void,
    loggedInUserToken: string
) => {
    if (!window.confirm(`Are you sure you want to delete this ${modelName}?`)) return;

    const apiEndpoint = `${endPoint}/${rowId}?token=${loggedInUserToken}`;

    try {
        const response = await apiService.deleteRequest(apiEndpoint);

        // Check for 204 No Content status
        if (response.status === 204) {
            toast.success(`${modelName} deleted successfully.`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            loadData(); // Refresh table data
        } else {
            toast.error(response.message || `Error occurred while deleting ${modelName}.`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    } catch (error: any) {
        if (error.response && error.response.status === 401) {
            toast.error("Invalid token. Please log in again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } else {
            toast.error(error.message || `Error occurred while deleting ${modelName}.`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
};

export { handleDelete };